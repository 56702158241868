import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import cc.rbbl.cellular_automaton.CellularAutomaton
import cc.rbbl.cellular_automaton.generateCellGrid
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.name
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLDivElement
import kotlin.js.Date.Companion.now
import kotlin.math.min

fun main() {
    val initialSeed: Long = now().toLong()
    var seedInput: String by mutableStateOf(initialSeed.toString())
    var seed: String by mutableStateOf(seedInput)
    var widthInput: Int by mutableStateOf(100)
    var width: Int by mutableStateOf(widthInput)
    var heightInput: Int by mutableStateOf(100)
    var height: Int by mutableStateOf(heightInput)
    var generations: Int by mutableStateOf(50)
    var toroidal: Boolean by mutableStateOf(false)
    var grid: Array<Array<Boolean>> by mutableStateOf(generateCellGrid(width, height, seed))
    val canvas: HTMLCanvasElement = document.getElementById("canvas") as HTMLCanvasElement

    window.addEventListener("resize", callback = {
        canvas.width = calcBoardSize()
        canvas.height = calcBoardSize()
        drawBoardOnCanvas(canvas, grid, width, height)
    })

    renderComposable(rootElementId = "root") {
        H1 {
            Text("Cellular Automaton")
        }
        P {
            Text("This is a small Demo for the ")
            A("https://gitlab.com/rbbl/cellular-automaton") {
                Text(" Cellular Automaton Lib")
            }
        }
        P {
            Label("seed") {
                Text("Seed: ")
            }
            TextInput(seedInput) {
                name("seed")
                onInput {
                    seedInput = it.value
                }
            }
        }
        P {
            Label("width") {
                Text("Width: ")
            }
            TextInput(widthInput.toString()) {
                name("width")
                inputMode(InputMode.Numeric)
                onInput {
                    widthInput = it.value.toInt()
                }
            }
            Label("height") {
                Text("Height: ")
            }
            TextInput(heightInput.toString()) {
                name("height")
                inputMode(InputMode.Numeric)
                onInput {
                    heightInput = it.value.toInt()
                }
            }
        }
        P {
            Button({
                onClick {
                    seed = seedInput
                    width = widthInput
                    height = heightInput
                    grid = generateCellGrid(width, height, seed)
                    drawBoardOnCanvas(canvas, grid, width, height)
                }
            }) {
                Text("Generate")
            }
        }
        P {
            Text("Seed: $seed; Width: $width; Height: $height;")
        }
        P {
            Label("generations") {
                Text("Generations: ")
            }
            TextInput(generations.toString()) {
                name("generations")
                inputMode(InputMode.Numeric)
                onInput {
                    generations = it.value.toInt()
                }
            }
            Label("toroidal") {
                Text("toroidal: ")
            }
            CheckboxInput(toroidal) {
                name("toroidal")
                onInput {
                    toroidal = it.value
                }
            }
        }
        P {
            Button({
                onClick {
                    val automaton = CellularAutomaton(grid, toroidal)
                    grid = automaton.simulateGenerations(generations, false)
                }
            }) {
                Text("Simulate")
            }
        }
        drawBoardOnCanvas(canvas, grid, width, height)
    }
    canvas.width = calcBoardSize()
    canvas.height = calcBoardSize()

    drawBoardOnCanvas(canvas, grid, width, height)
}

fun calcBoardSize(): Int {
    val width = window.innerWidth - (document.getElementById("root") as HTMLDivElement).offsetWidth - 50
    val height = window.innerHeight - 50

    return min(width, height)
}

fun drawBoardOnCanvas(canvas: HTMLCanvasElement, data: Array<Array<Boolean>>, width: Int, height: Int) {
    (canvas.getContext("2d") as CanvasRenderingContext2D).apply {
        fillStyle = "#ffffff"
        fillRect(0.0, 0.0, canvas.width.toDouble(), canvas.height.toDouble())
    }
    (canvas.getContext("2d") as CanvasRenderingContext2D).apply {
        val cellHeight = (canvas.height.toDouble() / height).let {
            if (it < 1) {
                1
            } else {
                it
            }
        }.toDouble()
        val cellWidth = (canvas.width.toDouble() / width).let {
            if (it < 1) {
                1
            } else {
                it
            }
        }.toDouble()
        fillStyle = "#000000"
        data.forEachIndexed { xIndex, column ->
            column.forEachIndexed { yIndex, value ->
                if (value) {
                    fillRect(xIndex * cellWidth, (((yIndex) * -1) + height - 1) * cellHeight, cellWidth, cellHeight)
                }
            }
        }
    }
}